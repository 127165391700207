import './App.css';

function NavX1(){
    return(
    <>
        <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                    <a class="navbar-brand" href="https://onsunday.store/">onsunday.shop</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav justify-content-center">
                        <li class="nav-item active">
                        <a class="nav-link active" aria-current="page" href="#">Stores</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="#">Products</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="#">Discounts</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="#">Support</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="#">Login</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
    )
}

export default NavX1;