import logo from './logo.svg';
import './App.css';
import { supabase } from './supabase_client';
import { useState, useEffect } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import BasicNav from './basic_nav';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavBarPlus from './navbar';
import NavX1 from './nav_x1';
import Listing from './listing';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
/*
<div class="card" style="width: 18rem;">
  <img src="..." class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>
*/

function App() {
  const router = createBrowserRouter([
    {},
    {}
  ])
  const [user,setuser] = useState([])
  console.log(user)
  useEffect(() => {
    fu()
  }, [])
  async function fu(){
    const {data} = await supabase.from("ONSUNDAYSTORE_LOCATIONS").select("*")
    setuser(data)
  }    

  return (
    <>
      <NavX1></NavX1>
      <Listing></Listing>
    </>
  //   <div>
  //   <div className='xi'>

  //       {user.map((u) =>
  //       <div>
          
  //         <h2 className='grid-container'></h2>
  //         <Card style={{ width: '18rem' }}>
  //     <Card.Body>
  //       <Card.Title>{u.store_name}</Card.Title>
  //       <Card.Subtitle className="mb-2 text-muted">{u.store_address}, {u.store_city}</Card.Subtitle>
  //       <Card.Text>
  //         Some quick example text to build on the card title and make up the
  //         bulk of the card's content.
  //       </Card.Text>
  //       <Card.Link href="#">Card Link</Card.Link>
  //       <Card.Link href="#">Another Link</Card.Link>
  //     </Card.Body>
  //   </Card>        </div>
  //       // <tr>
  //       //   <td>{u.store_name}</td>
  //       //   <td>{u.store_address}</td>
  //       //   <td>{u.store_city}</td>
  //       //   <td>{u.CITY_COUNTRY_NAME}</td>
  //       // </tr>
  //         )}
  //   </div>
  // </div> 
  );
}

export default App;
